<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg12 md12 sm12 xs12>
                    <h-currency-input v-model="selectedItem.VolumeMin" :decimal="2" label="Số khối (Min)" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <h-currency-input v-model="selectedItem.VolumeMax" :decimal="2" label="Số khối (Max)" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <h-currency-input v-model="selectedItem.Amount" :decimal="1" label="Tiền phí (¥)" class="input-group--focused"></h-currency-input>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
export default {
    computed: {
        ...mapState({
            loading: state => state.feeReinforce.loading,
            selectedItem: state => state.feeReinforce.selected
        }),
        modifyId() {
            return this.$route.params.Pid;
        }
    },
    watch: {
        modifyId() {
            this.refreshData();
        }
    },
    methods: {
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("feeReinforce/getDetail", this.modifyId);
            }
            else {
                this.$store.commit("feeReinforce/setDetail", { data: {
                        ID: 0,
                        VolumeMin: 0,
                        VolumeMax: 0,
                        Amount: 0
                    }
                })
            }
        },
        saveData() {
            this.$store.dispatch("feeReinforce/setDetail", {
                data: {
                    ...this.selectedItem,
                    ModifiedBy: this.$store.state.authentication.user.username
                },
                id: this.selectedItem.ID
            });
            window.getApp.$emit("APP_NAVIGATE", "FeeReinforceList");
        },
    },
    mounted() {
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>

<style>
</style>
